@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

/*
    Add styles that will be appended to the stylesheet
    Custom styles must be added before the utilities so it can allow for other styels to extended them
*/
@layer components{
	.quote-tabs{ @apply group flex-1 p-4 text-center text-xs lg:text-sm; }
	.quote-tabs-active{ @apply bg-blue-900 text-white font-semibold; }
    .quote-tabs-inactive{ @apply bg-gray-100 text-gray-500 hover:bg-gray-200 hover:text-gray-900 font-normal cursor-pointer focus:outline-none; }
	.quote-tabs-aria{ @apply absolute inset-x-0 bottom-0 h-0.5; }
	.btn-light{ @apply px-4 py-1 border border-transparent rounded-full shadow-sm text-lg text-white bg-blue-400 focus:outline-none; }
	.btn-navy{ @apply ml-2 px-3.5 py-2 border border-transparent rounded-full shadow-sm text-white focus:outline-none; }
	.btn-transparent{ @apply border-2 border-blue-400 text-blue-400 bg-transparent rounded-full whitespace-nowrap; }
	.radio-checkbox-label{ @apply rounded-full relative px-2 py-1 flex float-left cursor-pointer focus:outline-none hover:bg-gray-200;}
	.radio-checkbox{ @apply h-4 w-4 mt-0.5 cursor-pointer; }
	.promo-title{ @apply text-lg font-semibold text-blue-400 uppercase hover:underline; }
	.promo-readmore{ @apply text-blue-900 uppercase text-sm mt-1; }
	.footer-link{ @apply text-gray-300 hover:text-white hover:underline; }
	.footer-header{ @apply font-semibold text-blue-400 tracking-wider uppercase; }
	.footer-list{ @apply mt-4 space-y-4; }
    .style-input{ @apply mt-1 px-3 h-12 block w-full focus:outline-none border border-gray-300 rounded-full;}
    .style-select{ @apply mt-1 px-3 h-12 block w-full border border-gray-300 rounded-full; }
    .style-quote-wrap{ @apply bg-blue-400 text-blue-900 cursor-pointer focus:outline-none ; }  
    .style-quote-input{ @apply shadow-sm block w-full text-gray-700 z-10 bg-white border-gray-300 pl-8 pr-2 py-3 h-12 focus:outline-none rounded-full; }
    .style-quote-icons{ @apply text-gray-700 text-lg absolute top-4 left-3 z-50 focus:outline-none; }  
    .style-quote-pills{ @apply inline-flex items-center m-1 px-3 py-1.5 border border-transparent text-xs rounded-full shadow-sm text-gray-400 bg-white hover:bg-gray-50 focus:outline-none;}  
    .style-addons-checkbox{ @apply rounded-full relative px-2 mb-2 py-1 flex float-left cursor-pointer focus:outline-none hover:bg-gray-200;}  
}


/*
	CUSTOM CSS 
*/
html,body{ font-family: "Ubuntu", "Roboto", "Helvetica", "Arial", "sans-serif";}
table{margin: 10px 0;}
.bg-blue-400,.bg-blue-400:hover{ background-color: #00AEEF !important;}
.bg-blue-900,.bg-blue-900:hover,.hover\:bg-blue-900:hover{ background-color: #0018A2 !important;}
.text-blue-400,.text-blue-400:hover{ color: #00AEEF !important;}
.text-blue-900,.text-blue-900:hover{ color: #0018A2 !important;}
.border-blue-400,.border-blue-400:hover,.hover\:border-blue-400:hover { border-color: #00AEEF !important;}
.border-blue-900,.border-blue-900:hover,.hover\:border-blue-900:hover { border-color: #0018A2 !important;}
.bg-dark{ background-color: #0E0A4A !important;}
.bg-header-footer{ background-color: #0E0A4A !important;}
.bg-header-footer .border-t { border-color:rgb(0, 24, 162,0.5) !important;   }

.select-location__indicator-separator{ display: none;}

.react-datepicker{ border: 1px solid rgba(209, 213, 219, 1) !important; z-index: 10000 !important;}
.react-datepicker__header{ border-bottom: none !important; background-color: rgba(243, 244, 246, 1) !important; }
.react-datepicker-popper{ z-index: 50 !important;}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before{ border-bottom-color: rgba(209, 213, 219, 1) !important;}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after{ border-bottom-color: rgba(243, 244, 246, 1) !important;}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item{height: auto !important; }
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
	background-color: #0018a2 !important;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
	background-color: transparent !important; color: #000 !important;
	border: 1px solid #eeeeee !important;; 
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover,
.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover{
	background-color: #f3f4f6 !important; color: #000 !important; 
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before{
	border-color: #d1d5db !important;;
}

.react-datepicker__navigation{ top: 4px; }
/*
	Radial Text Gradient
*/
.text-gradient{ 
	background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(102,102,102,1) 100%);
  	-webkit-background-clip: text;
  	-webkit-text-fill-color: transparent;
}

/*
	Wordpress Page Content Styles
*/
.page-content p{margin: 0 0 10.5px;}
.page-content h3{ font-size: 1.6rem !important;}
.page-content h4{font-size: 21px;margin-top: 10.5px; margin-bottom: 10.5px;}
.page-content a { color: #00AEEF !important; text-decoration: none;}
.page-content a:hover{ color: #00AEEF !important; text-decoration: underline;}
.page-content hr { margin-top: 21px; margin-bottom: 21px; border: 0; border-top: 1px solid #ddd; }
.page-content .su-note{ clear: both ;}
.page-content .su-note-inner{ padding:20px; margin-bottom: 20px; background-color: rgba(229, 231, 235, 1 ) !important;}
.page-content ol{ padding-left: 20px; list-style: auto;}
.page-content ul{ padding-left: 20px; list-style: disc;}
.page-content td, .page-content th { padding: 10px; }
.page-content#gps-rentals .su-tabs-nav{ padding: 10px;  font-weight: bold; font-size: 16px;}
.page-content#door2door-transfers .su-tabs-pane{ float:left; padding: 10px; width: 33.33333333%; }
.page-content#door2door-transfers .su-tabs-nav span{ padding: 10px; width: 31%;display: block; float: left; font-weight: bold; margin: 0 1%; font-size: 16px; color: #0018A2 !important; text-transform: uppercase; background-color: rgba(229, 231, 235, 1 ); border-radius: 0.375rem; }
.page-content .su-box-title{ background-color: #0018A2 !important;border-radius:3px !important}
.page-content .su-box-title, .page-content .su-box-content{ padding: 10px;}

#modal .su-note-inner{ padding: 10px;  font-weight: bold; font-size: 16px; margin: 10.5px 0;}
#modal p{margin: 0 0 10.5px;}
#modal h3{ font-size: 1.6rem !important;}
#modal h4{font-size: 21px;margin-top: 10.5px; margin-bottom: 10.5px;}
#modal a { color: #00AEEF !important; text-decoration: none;}
#modal a:hover{ color: #00AEEF !important; text-decoration: underline;}
#modal hr { margin-top: 21px; margin-bottom: 21px; border: 0; border-top: 1px solid #ddd; }

.page-content .su-spoiler-title,#modal .su-spoiler-title{ background-color: #0018A2; color: #fff; font-size: 16px; padding: 10px; border-radius:3px; margin-bottom: 5px;}

#is404 .su-column{ float:left; width:50%}

/* Large Desktops */
@media only screen and (max-width : 1200px) {

}

/* Medium Devices, Desktops : col-md */
@media only screen and (max-width: 991px) {

}

@media only screen and (max-width: 860px) {

}

@media only screen and (max-width: 768px) {

}

/* Small Devices, Desktops : col-sm */
@media only screen and (max-width: 767px) {
	.page-content#door2door-transfers .su-tabs-pane{ width: 100%; }
	.page-content#door2door-transfers .su-tabs-nav span{ width: 98%; }
}


/* Extra Small Devices, Phones : col-xs */
@media only screen and (max-width: 575px) {

}


/*--------------------------------------------------------------
3.0 Alignments
--------------------------------------------------------------*/

.alignleft {
	display: inline;
	float: left;
	margin-right: 1.5em;
  margin-bottom: 1.0em;
}

.alignright {
	display: inline;
	float: right;
	margin-left: 1.5em;
}

.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

/*--------------------------------------------------------------
4.0 Clearings
--------------------------------------------------------------*/

.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.entry-footer:before,
.entry-footer:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after,
.nav-links:before,
.nav-links:after,
.pagination:before,
.pagination:after,
.comment-author:before,
.comment-author:after,
.widget-area:before,
.widget-area:after,
.widget:before,
.widget:after,
.comment-meta:before,
.comment-meta:after {
	content: "";
	display: table;
	table-layout: fixed;
}

.clear:after,
.entry-content:after,
.entry-footer:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after,
.nav-links:after,
.pagination:after,
.comment-author:after,
.widget-area:after,
.widget:after,
.comment-meta:after {
	clear: both;
}

